// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBUVEYTIAAEdlqrEJMNAS6RGpj1wkRqxW8",
  authDomain: "yeka-studio-website.firebaseapp.com",
  databaseURL: "https://yeka-studio-website-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "yeka-studio-website",
  storageBucket: "yeka-studio-website.appspot.com",
  messagingSenderId: "329215411265",
  appId: "1:329215411265:web:0b3a8ee12bac37357fb2c0",
  measurementId: "G-C0VKESW6WV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeQFbQlAAAAAMKOXMj-gIqM9OdHQFNQk_DJXwf_'),
  isTokenAutoRefreshEnabled: true
});

const analytics = getAnalytics(app);
export const db = getDatabase(app);




