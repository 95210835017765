import { db } from "./../../firebase";
import { uid } from "uid";
import { set, ref, onValue, remove, update } from "firebase/database";
import { useState, useEffect } from "react";
import Moment from 'moment';
import validator from 'validator';
import { TypeAnimation } from 'react-type-animation';


function App() {
  const [data, setData] = useState({});
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [nameError, setnameError] = useState(false);
  const [phoneError, setphoneError] = useState(false);
  const [messageError, setmessageError] = useState(false);

  //    email: { message: 'sikkkk mish' },
  // name: { message: 'sikkkk mish' },
  // phone: { message: 'sikkkk mish' },
  // message: { message: 'sikkkk mish' }

  function validation(values) {

    let errors = false;

    if (!values.email) {
      errors = true
      console.log("asdas", values.email)

      setemailError('Required')
    } else if (!validator.isEmail(values.email)) {
      setemailError('Invalid email address')
      errors = true
    } else {
      setemailError(false)
    }

    if (!values.name) {
      errors = true
      setnameError('Required')
    } else {
      setnameError(false)
    }

    if (!values.message) {
      errors = true
      setmessageError('Required')
    } else {
      setmessageError(false)
    }

    if (!values.phone) {
      errors = true
      setphoneError('Required')
    } else if (!validator.isMobilePhone(values.phone)) {
      errors = true
      setphoneError('Invalid phone number')
    } else {
      setphoneError(false)
    }


    if (!errors) {
      writeToDatabase(values)
    }
  }

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const writeToDatabase = async (data) => {
    const uuid = uid();
    const final = data
    final.date = Moment().format('DD-MM-YYYY hh:mm a');
    final.status = 0;
    console.log(final)
    set(ref(db, 'contacts/' + uuid), final)
      .then(() => {
        setDone(true)
      })
      .catch((error) => {
        setDone(false)
        setError(true)
      });


  };



  return (
    <div className="App">
      <header>
        <div className="logoBox">
          <div className="logo"></div>
          <div className="title">
            <span>Yeka</span>
            <span></span>
            <TypeAnimation
              sequence={[
                'Creative',
                2000,
                'Tech',
                2000,
                'Software',
                2000,
                'Design',
                2000,
                () => {
                  console.log('Sequence completed');
                }
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
            />
            <span>Studio</span>
          </div>
          <div className="sub-title"><span>Creating</span> <span>the</span> <span>Future</span></div>

        </div>
        <div className="sub-title"><span>Creating</span> <span>the</span> <span>Future</span></div>

        <div className="bottom"></div>
      </header>

      <section className="features">
        <div className="feature">
          <div className="title">Future-proof</div>
          <div className="desc">Providing solutions that are designed to adapt to future shifts and innovations.</div>

        </div>

        <div className="feature">
          <div className="title">Quality driven</div>
          <div className="desc">Solutions are crafted with a keen eye for detail and an uncompromising dedication to excellence.</div>

        </div>
        <div className="feature">
          <div className="title">Experienced Innovators</div>
          <div className="desc">By a team of experienced innovators who have been delivering cutting-edge technology solutions for over 15 years.</div>

        </div>

      </section>

      <section className="mission" id="contact">
        <div className="title">
          Our mission is to help game-changing businesses reach new heights by transforming their future potential into a well-crafted tech strategy and a genuine product presence.<br /><br />From stunning websites and elegant mobile apps to gadgets and AI engines that can transform your business, we've got you covered.
        </div>

      </section>




      <div className="form-container">
        <div className="form">
          <div className="mug"><div className="talk"></div></div>
          <div className="form-box">

            <form className="contact-form">
              <div className="contact-form-title"></div>
              {done ? <div class="alert alert-success" role="alert">
                <div className="title">
                  Your message has been successfully submitted. <br />
                  Our team will review your inquiry and get back to you as soon as possible to schedule the session. Thank you for reaching out!</div>
              </div> : ''}
              {error ? <div class="alert alert-danger" role="alert">Error submitting message. Please try again or contact us via phone/email.</div> : ""}

              {!done ? <>
                <div class="form-group">
                  <input type="text" className="form-control" id="Name" placeholder="name_" name="name" value={data.name} onChange={handleChange} />
                  {nameError && <span className="inputerror" role="alert">{nameError}</span>}
                </div>
                <div class="form-group">
                  <input type="email" className="form-control" id="InputEmail1" aria-describedby="emailHelp" placeholder="email_" name="email" value={data.email} onChange={handleChange} />
                  {emailError && <span className="inputerror" role="alert">{emailError}</span>}
                </div>
                <div class="form-group">
                  <input type="tel" className="form-control" id="Phone" placeholder="phone_" name="phone" value={data.phone} onChange={handleChange} />
                  {phoneError && <span className="inputerror" role="alert">{phoneError}</span>}
                </div>
                <div class="form-group">
                  <textarea className="form-control" id="Message" placeholder="message_" name="message" value={data.massage} onChange={handleChange} />
                  {messageError && <span className="inputerror" role="alert">{messageError}</span>}
                </div>
                <div class="btn btn-primary" onClick={() => { validation(data) }}>Submit</div>
              </> : ''}</form>

          </div>
        </div>

      </div>

      <footer>
        <div className="social">
          <div>Connect with us</div>
          <div className="border"></div>
          <a href="https://www.linkedin.com/company/yeka-studio" className="linkdin" target="_blank" />
          <a href="https://www.instagram.com/yeka_studio/" className="instagram" target="_blank" />
          <a href="https://twitter.com/YekaStudio" className="twitter" target="_blank" />
        </div>


        <div className="Copyright">© Copyright 2023 Yeka Studio OÜ ∙ Tallinn, Estonia</div>
      </footer>
    </div>
  );
}

export default App;
